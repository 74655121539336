/* MushroomTaxonomy.css */

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.mushroom-gallery {
  text-align: center;
  margin-top: 20px;
}

.mushroom-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
  padding: 20px;
}

.mushroom-card {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.mushroom-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.mushroom-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

h2 {
  margin-bottom: 20px;
  color: #000;
}
