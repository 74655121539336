.flex-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.sidenav {
  width: 200px;
  background-color: #f0f0f0;
  padding: 15px;
  position: fixed;
  height: 100%;
  overflow: auto;
}

.sidenav a {
  display: block;
  margin: 10px 0;
  text-decoration: none;
  color: black;
}

.main-content {
  margin-left: 220px;
  padding: 20px;
  flex-grow: 1;
}

.memory-match {
  text-align: center;
}

.memory-grid {
  display: grid;
  grid-template-columns: repeat(4, 100px);
  gap: 10px;
  justify-content: center;
}

.memory-card {
  width: 100px;
  height: 100px;
  background-color: #ccc;
  border: 1px solid #aaa;
  position: relative;
  cursor: pointer;
}

.memory-card.flipped img {
  display: block;
}

.memory-card img {
  display: none;
  width: 100%;
  height: 100%;
}

.card-back {
  width: 100%;
  height: 100%;
  background-color: #555;
  display: block;
}
.game-info {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center-align the content */
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}

.game-info p {
  margin: 5px 0; /* Add some space between the lines */
}


.game-over {
  text-align: center;
  margin-top: 20px;
}

.game-over h2 {
  font-size: 24px;
  color: #4caf50;
}

.game-over button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
}


